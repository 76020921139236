<template>
  <div class="get_record">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="name" placeholder="请输入优惠券名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="type" placeholder="请选择类型" @change="onChange" clearable>
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="status" placeholder="请选择状态" @change="onChange" clearable>
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <!-- <el-button type="primary" @click="handleExport">导出</el-button> -->
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="couponCode" label="优惠券编号"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="couponName" label="优惠券名称"></el-table-column>
          <el-table-column label="类型" width="100">
            <template slot-scope="scope">
              <span>{{ mapCouponType(scope.row.couponType) }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="createdTime" label="领券时间"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="userCode" label="用户编号"></el-table-column>
          <el-table-column label="有效期限" show-overflow-tooltip min-width="220">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate }} 至 {{ scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" label="状态">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.userStatus == '2' ? '#EB2500' : '#68706E' }">{{ mapUserStatus(scope.row.userStatus) }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="160" label="使用时间">
            <template slot-scope="scope">
              <span>{{ scope.row.updatedTime || "-" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      shopId: this.$store.state.userInfo.shopId,
      name: "",
      type: "",
      status: "",
      options1: [
        { value: "1", label: "满减券" },
        { value: "2", label: "折扣" },
        // { value: '3', label: '代金券' },
      ],
      options2: [
        { value: "0", label: "待使用" },
        { value: "1", label: "已使用" },
        { value: "2", label: "已过期" },
      ],
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getCouponRecordList()
  },
  methods: {
    mapCouponType(val) {
      return this.options1.find(e => e.value == val).label || ""
    },
    mapUserStatus(val) {
      return this.options2.find(e => e.value == val).label || ""
    },
    handleExport() {
      this.$confirm("", "以后再做", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {})
        .catch(() => {})
    },
    getCouponRecordList() {
      this.loading = true
      this.$axios
        .get(this.$api.couponRecordList, {
          params: {
            shopId: this.shopId,
            couponName: this.name,
            couponType: this.type,
            status: this.status,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list || []
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getCouponRecordList()
    },
    onChange() {
      this.tableData = []
      this.currentPage = 1
      this.getCouponRecordList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getCouponRecordList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getCouponRecordList()
    },
  },
}
</script>

<style scoped lang="scss">
.get_record {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
